@import "styles/utils";

$footer-height-desktop: var(--footer-height-desktop);

.appFooter {
  @include prevent-tab-highlights;
  max-height: $footer-height-desktop;
  padding: 140px 110px 40px 145px;
  background-color: var(--bg-color);

  @include desktop {
    padding: 135px 70px 40px 125px;
    font-size: 0.2rem;
  }

  @include tablet {
    padding: 30px 50px;
  }

  @include phone {
    padding: 30px 30px 40px 30px;
  }

  &.appFooterTabletMobileDisplayed {
    margin-bottom: 60px;
  }

  .appFooterContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .footerLogo {
      flex: 10% !important;
      width: 6.7rem !important;
      height: 5rem !important;
      svg {
        width: 100%;
        height: 100%;
      }
      @include tabletDown {
        display: none;
      }
    }
  }

  .footerColumns {
    width: 100%;
    flex: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    @include desktop {
      flex: 90%;
    }

    @include phone {
      justify-content: center;
    }

    .footerColumn {
      color: var(--primary-text-color);
      text-align: right;
      padding-left: 50px;
      width: fit-content;
      font-weight: 600;
      font-size: 0.9rem;
      letter-spacing: 0;
      line-height: 18px;

      @include tablet {
        padding-left: 25px;
      }

      @include phone {
        padding: 10px;
        margin: auto;
      }
    }
    .column:not(:first-child) {
      white-space: pre;
    }
    .footerColumnDropdown {
      min-width: 15%;
    }
  }
}
