@import "styles/utils";

.stepProgressBar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: min(500px, 100%);


  .stepDot {
    border-radius: 50%;
    font-size: 16px;
    line-height: 17px;
    padding: .2em;
    width: 1.2em;
    height: 1.2em;
    z-index: 1;
    display: grid;
    place-content: center;
    color: var(--primary-text-color);
    background: var(--primary-text-color-d-30);
    cursor: default;
    font-family: "Arial Black",Arial,serif;
    &.activeDot {
      background: var(--primary-color);
    }
    &.activeDotCursor {
      cursor: pointer;
    }
  }

  .barContainer {
    position: absolute;
    height: 8%;
    width: calc(100% - 20px);
    top: 50%;
    left: 10px;
    translate: 0 -50%;
    background: var(--primary-text-color);
    z-index: 0;
    .barFill {
      background: var(--primary-color);
      height: 100%;
    }
  }
}