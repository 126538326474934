@import "styles/utils";

$header-top-height: var(--header-height-mobile);

.headerMobile {
  @include prevent-tab-highlights;
  width: 100vw;
  min-height: $header-top-height;
  z-index: 1;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, var(--bg-color) 80%, transparent);
  .headerTop {
    display: flex;
    height: $header-top-height;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5px;
    background: var(--bg-color);

    @include phone() {
      height: auto;
      margin: 30px 0 0;
    }
  }

  .headerBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(to bottom, var(--bg-color), transparent);
  }
  .headerLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 92px;
    height: 45%;
    flex: 1 auto;
  }

  .headerRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45%;
    margin-right: 22px;
    flex-basis: 70px;
  }
}
