@import "styles/utils";

.splash {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    @include display-algin(center);
    z-index: 2000;
    background-color: #0c101b;
    background-image: linear-gradient(to bottom, rgba(14, 17, 26, 0), #000929);
}

.container {
    display: flex;
    flex-direction: column;
    @include display-algin(center);
    height: 100%;
}

.spinner {
    min-height: 20vh;
    padding: 5px;
}

.logo {
    margin: 90px 0 40px;
    max-width: 615px;
    max-height: 20vh;
}

.banner {
    margin: 90px 0 0;
    max-width: 615px;
    max-height: 107px;
}

.branding {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    @include display-algin(center);
}