@import "styles/utils";

.headerBrandLogo {
  height: 3.5rem;

  @include tablet {
    height: 2.5rem;
  }
  @include phone {
    height: 5.5rem;
    margin-bottom: 5px;
  }
}

.headerNoMargin {
  margin: 0;
}

.logo {
  image-rendering: -webkit-optimize-contrast;
  width: auto;
  height: 70px;

  @include desktop(){
    width: 70px;
    height: 70px;
  }

  @include phone() {
    width: auto;
    height: 48px;
  }

  @include tablet(){
    width: auto;
    height: 48px;
  }

  @include tabletDown(){
    width: auto;
    height: 48px;
  }

  @include phone-landscape() {
    width: auto;
    height: 48px;
  }
}
