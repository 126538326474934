@import "styles/utils";

$select-border: solid 1px rgba(255, 255, 255, 0.22);
$select-color: #ffffff;
$select-font-size: 1.375rem;
$select-letter-spacing: 0.9px;
$select-text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
$select-opacity: 0.87;
$select-padding-horizontal: 8px;
$select-padding-vertical: 8px;

.Select {
  @include prevent-tab-highlights;
  display: inline-block;
  position: relative;
  font-size: $select-font-size;
  letter-spacing: $select-letter-spacing;
  text-shadow: $select-text-shadow;
  min-width: 12rem;
  margin-bottom: 3.35rem;
  overflow: hidden;
  @include phone{
    min-width: fit-content;
  }
}

.Select-disabled {
  opacity: 0.3;

  input {
    cursor: not-allowed;
  }
}

.Select-single {
  cursor: pointer;

  .Select-selector {
    display: flex;
    position: relative;
    cursor: pointer;

    .Select-selection-search,
    .Select-selection-search-input {
      width: 0;
      cursor: pointer;
    }
  }
}

.Select-selection-item,
.Select-selection-placeholder {
  padding-right: 45px;
  pointer-events: none;
  opacity: $select-opacity;
}

.Select-selector {
  padding: 0 $select-padding-horizontal $select-padding-vertical calc($select-padding-horizontal + 18px);
  background-color: var(--bg-color);
  @include phone{
    max-width: 145px;
  }
}

.Select-selection-search-input {
  border: none;
  outline: none;
  padding: 0px;
  width: 250px;
  padding-right: 40px;
}

.Select-allow-clear .Select-clear {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.Select-show-arrow .Select-arrow {
  pointer-events: none;
  position: absolute;
  right: 4px;
  top: 0;
  transform: translateY(-25%);

  svg {
    transition: transform 0.2s;
    display: block;
    height: 100%;
    width: 3rem;
    color: var(--primary-text-color);
  }
}

.Select-open .Select-arrow {
  svg {
    transform: rotate(-0.5turn);
  }
}

.Select-dropdown {
  position: absolute;
  background-color: var(--bg-color);
  box-sizing: border-box;
  font-size: $select-font-size;
  letter-spacing: $select-letter-spacing;
  text-shadow: $select-text-shadow;
  line-height: 2.125rem;
  margin-top: 5rem;
  overflow: hidden;
  padding-left: calc($select-padding-horizontal + 18px);
  @include tabletDown {
    min-height: min-content !important;
  }
  &-hidden {
    display: none;
  }
}

.Select-item {
  padding: $select-padding-vertical $select-padding-horizontal $select-padding-vertical 0;
  cursor: pointer;
}

.Select-item-option {
  font-weight: lighter;
  position: relative;
  :hover {
    font-weight: normal;
  }
}

.Select-item-option-content {
  opacity: $select-opacity;
}

.Select-item-option-disabled {
  color: #999;
}

.Select-item-empty {
  text-align: center;
}

.rc-virtual-list {
  padding: 2rem 2rem 2rem 0rem;
}

.rc-virtual-list-holder {
  max-height: 40rem;
  max-width: 15rem;
}
.rc-virtual-list-scrollbar{
  background-color: var(--primary-text-color);
  opacity: 0.2;
}

.rc-virtual-list-scrollbar-thumb{
  background-color: var(--primary-color)!important;
}


.sortDrawerVirtualList{
  .rc-virtual-list-holder {
    max-height: 30rem !important;
  }
  .rc-virtual-list-scrollbar{
    display: none;
  }
  
  .rc-virtual-list-scrollbar-thumb{
    display: none;
  }
}