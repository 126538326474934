@import "styles/utils";

.headerTabletDesktop {
  @include prevent-tab-highlights;
  box-sizing: border-box;
  width: 100vw;
  min-height: 108px;
  z-index: 1009;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: var(--app-menu-padding-top) 48px 20px 48px;
  background: var(--bg-color);
  background-image: linear-gradient(to bottom, var(--bg-color), transparent);
  transition: background 0.2s ease-out;

  @include tabletDown {
    position: absolute;
    padding: var(--app-menu-padding-top) 15px;
    background: linear-gradient(to bottom, #000000, transparent);
  }

  &.headerTop {
    background: transparent;
  }

  >.headerLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  >.headerRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-self: flex-end;
    flex: 1;

    @include tabletDown {
      flex-basis: 90px;
    }
  }
}
