@import "theme";
@import "functions";
@import "styles/variables";
@import "src/styles/utils";
@import "src/fonts/default-font";

html,
body {
  width: 100%;
}

html {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow-x: hidden;
  background-color: var(--bg-color);
  color: var(--primary-text-color);
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
  scrollbar-color: var(--primary-color) var(--cell-background-color);
  scrollbar-width: thin;
}

input {
  box-shadow: 0 0 0px 1000px $input-bg inset !important;
}

a {
  text-decoration: none;
  color: inherit;
}

// Fix to pass Lighthouse and still not display labels for some fields
.label--hidden {
  position: absolute;
  top: -10000px;
  left: -10000px;
}

.text-main {
  color: var(--primary-text-color);
}

.text-center {
  text-align: center;
}

.text-link {
  opacity: 0.8;
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.text-upper {
  text-transform: uppercase;
}

.text-bold {
  font-weight: normal;
}

.text-small {
  font-size: 14px;
  letter-spacing: 0.52px;
}

.text-normal {
  font-size: 16px;
  letter-spacing: 0.6px;
}

.text-large {
  font-size: 22px;
  letter-spacing: 0.82px;
}

.dialog {
  max-width: 460px;

  .Dialog-content {
    .Dialog-body {
      box-sizing: border-box;
      padding: 54px;
    }
  }
}

.dialogTitle {
  font-size: 25px;
  line-height: 27px;
  margin-bottom: 24px;
  text-align: center;

  @include phone() {
    margin-bottom: -14px;
    margin-top: 75px;
  }
}

.dialogText {
  font-size: 17px;
  line-height: 19px;

  @include phone() {
    margin-top: 40px;
  }
}

.input-radio {
  box-shadow: none !important;
}

.ml-xs {
  margin-left: 5px;
}

.mt-md {
  margin-top: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

// FIXME move it away from global styles if possible
@import "components/Menu/Menu.module.scss";
@import "components/Dialog/Dialog.module.scss";
@import "components/Select/Select.module.scss";
@import "components/AppHeader/AppHeaderDesktopTablet/AppHeaderDesktopTablet.module.scss";
@import "components/AppHeader/AppHeaderMobile/AppHeaderMobile.module.scss";
@import "components/AppFooter/AppFooter.module.scss";
@import "screens/PaymentScreen/components/StepsProgressBar/StepsProgressBar.module.scss";
@import "components/AppHeader/components/HeaderLogo/HeaderLogo.module.scss";
@import "components/AppFooter/AppFooterDesktop/AppFooterDesktop.module.scss";
@import "components/SimpleOfferCard/SimpleOfferCard.module.scss";