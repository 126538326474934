@import "styles/utils";
@import "styles/variables";

.clickable {
  cursor: pointer;

  &:hover {
    border: 3px solid #335AFF;
  }
}

.OfferContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  min-width: 327px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px 5px;
  color: var(--primary-text-color);
  border: 3px solid var(--primary-text-color);
  position: relative;

  @include tabletDown() {
    min-width: auto;
    flex: 1;
    padding: 16px 8px;
  }

  &.active {
    border: 3px solid var(--app-dot-color);

    &:hover {
      border: 3px solid #335AFF;
    }
  }

  .annualSave {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 13px;
    top: -12px;
    font-weight: 600;
    font-family: "Arial", serif;
    background-color: var(--app-dot-color);
    border-radius: 20px;
    min-width: 93px;
    font-size: 14px;
    line-height: 16px;
    height: 22px;
    max-width: 130px;
  }

  .OldPrice {
    font-weight: bold;
    color: grey;
    text-decoration: line-through;
    text-align: center;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .PriceTitle {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    word-wrap: anywhere;
    word-break: break-all;

    @include tabletDown() {
      font-size: 18px;
      word-break: break-word;
    }

    @include phone() {
      word-spacing: 999px;
    }
  }

  .PriceTitleDiscount100 {
    font-size: 40px;
    line-height: 42px;
    font-weight: bold;
    margin-top: 22px;
    margin-bottom: 20px;
    text-align: center;
    word-wrap: anywhere;
    word-break: break-all;

    @include phone() {
      font-size: 20px;
      word-break: break-word;
    }
  }

  .PriceOld {
    font-family: "Arial Black", Arial, serif;
    font-size: 14px;
    line-height: 16px;
    color: #B4A9A9;
    text-decoration: line-through;
    height: 16px;
  }

  .Price {
    font-family: "Arial Black", Arial, serif;
    font-size: 60px;
    text-align: center;
    line-height: 1;
    margin-bottom: 5px;
    min-height: 60px;

    @include tabletDown() {
      font-size: 40px;
      min-height: auto;
      font-weight: 900;
    }
  }

  .PriceDesc {
    font-weight: 900;
    font-size: 20px;
    text-align: center;

    @include phone() {
      font-size: 12px;
    }
  }

  .OfferDesc {
    font-weight: lighter;
    font-size: 15px;
    text-align: center;
    min-height: 15px;

    @include phone() {
      font-size: 12px;
    }
  }

  .Voucher {
    padding-bottom: 6px;
    min-height: 90px;
    height: max-content;

  }

  .VoucherSuccess {
    max-width: 360px;
    margin-top: 20px;
    font-size: 1.2em;
    display: flex;
    justify-content: center;

    &:first-child {
      margin-top: 10px;
    }

    svg {
      color: inherit;
      height: 30px;
      width: auto;
      margin-right: 5px;

      path:first-child {
        fill: var(--primary-color);
      }
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  .CTAButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
  }
}

.VoucherAdditionalInfo {
  margin-top: 12px;
  line-height: 18px;
  font-weight: lighter;
}

.ChooseOfferButton {
  justify-content: center;
  width: 100%;
  max-width: 90%;
  height: 54px;
  margin: 20px 0 10px !important;
  border-radius: $button-radius;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;

  @include tabletDown() {
    max-width: 215px;
    height: 48px;
    font-size: 20px;
    margin: 12px 0 10px !important;
  }
}