@font-face {
  font-family: "Raleway";
  src: url("Raleway/Raleway-Black.eot");
  src: local("Raleway Black"), local("Raleway-Black"),
    /*url("Raleway/Raleway-Black.eot?#iefix") format("embedded-opentype"),
    url("Raleway/Raleway-Black.woff2") format("woff2"),
    url("Raleway/Raleway-Black.woff") format("woff"),*/
    url("Raleway/Raleway-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("Raleway/Raleway-Bold.eot");
  src: local("Raleway Regular"), local("Raleway-Regular"),
    /*url("Raleway/Raleway-Bold.eot?#iefix") format("embedded-opentype"),
    url("Raleway/Raleway-Bold.woff2") format("woff2"),
    url("Raleway/Raleway-Bold.woff") format("woff"),*/
    url("Raleway/Raleway-Bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("Raleway/Raleway-Regular.eot");
  src: local("Raleway Light"), local("Raleway-Light"),
    /*url("Raleway/Raleway-Regular.eot?#iefix") format("embedded-opentype"),
    url("Raleway/Raleway-Regular.woff2") format("woff2"),
    url("Raleway/Raleway-Regular.woff") format("woff"),*/
    url("Raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}