// default colors
:root {
  --primary-color: #0033ff00;
  --primary-color-d-50: scale(#0031ff, $lightness: -50%);
  --primary-text-color: #ffffff;
  --primary-text-color-d-30: scale(#ffffff, $lightness: -30%);
  --secondary-color: #3c4573;
  --gray-text-color: #eeeeee;
  --secondary-text-color: #ffffff;
  --link-color: #0094ff;
  --link-color-d-50: scale(#0094ff, $lightness: -50%);
  --link-text-color: #ffffff;
  --link-text-color-d-30: scale(#ffffff, $lightness: -30%);
  --bg-color: #070523;
  --bg-color-o-30: opacity(#070523, 60%);
  --bg-gray: #eeeeee;
  --footer-link-color: #ffffff;
  --footer-link-hover-color: #ffffff;
  --footer-height-desktop: 90px;
  --footer-height-tablet: 90px;
  --header-height-mobile: 85px;
  --header-link-color: #ffffff;
  --header-link-hover-color: #ffffff;
  --font-family: "Montserrat", Helvetica, Arial, sans-serif;
  --cell-background-color: #212640;
  --app-menu-padding-top: 20px;
  --app-menu-top-fade-height: 7rem;
  --app-menu-top-fade-gradient: linear-gradient(
    to bottom,
    #000000a8,
    transparent
  );
  --app-padding-left-large: 78px;
  --app-padding-left-desktop-tablet: 50px;
  --app-padding-left-phone: 35px;
  --app-padding-right: 78px;
  --app-padding-top: 12rem;
  --app-list-padding-top: 45px;
  --app-list-padding-bottom: 0px;
  --app-spalsh-background-url: "";
  --search-header-margin-top: 35px;
  --search-header-margin-bottom: 74px;
  --app-dot-color:#0031ff;
}

$variables: (
  --primary-color: var(--primary-color),
  --primary-color-d-50: var(--primary-color-d-50),
  --primary-text-color: var(--primary-text-color),
  --primary-text-color-d-30: var(--primary-text-color-d-30),
  --secondary-color: var(--secondary-color),
  --secondary-text-color: var(--secondary-text-color),
  --link-color: var(--link-color),
  --link-color-d-50: var(--link-color-d-50),
  --link-text-color: var(--link-text-color),
  --link-text-color-d-30: var(--link-text-color-d-30),
  --bg-color: var(--bg-color),
  --bg-color-o-30: var(--bg-color-o-30),
  --footer-link-color: var(--footer-link-color),
  --footer-link-hover-color: var(--footer-link-hover-color),
  --header-height-mobile: var(--header-height-mobile),
  --header-link-color: var(--header-link-color),
  --header-link-hover-color: var(--header-link-hover-color),
  --font-family: var(--font-family),
  --cell-background-color: var(--cell-background-color),
  --app-menu-padding-top: var(--app-menu-padding-top),
  --app-menu-top-fade-height: var(--app-menu-top-fade-height),
  --app-menu-top-fade-gradient: var(--app-menu-top-fade-gradient),
  --app-padding-left-large: var(--app-padding-left-large),
  --app-padding-left-desktop-tablet: var(--app-padding-left-desktop-tablet),
  --app-padding-left-phone: var(--app-padding-left-phone),
  --app-padding-right: var(--app-padding-right),
  --app-spalsh-background-url: var(--app-spalsh-background-url),
  --app-list-padding-top: var(--app-list-padding-top),
  --app-list-padding-bottom: var(--app-list-padding-bottom),
  --app-dot-color: var(--app-dot-color),
);
